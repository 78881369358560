// components/ui/Textarea.js
import React from 'react';

export const Textarea = ({ className = "", ...props }) => {
  return (
    <textarea
      className={`
        min-h-[80px]
        w-full 
        rounded-md 
        border border-gray-300
        px-3 py-2 
        text-sm
        focus:outline-none 
        focus:ring-2 
        focus:ring-blue-500
        disabled:cursor-not-allowed 
        disabled:opacity-50
        ${className}
      `}
      {...props}
    />
  );
};

export default Textarea;
