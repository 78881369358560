import React, { useState } from "react";
import {
  Menu,
  X,
  ChevronDown,
  Briefcase,
  Plus,
  BanknoteIcon,
  Shield,
} from "lucide-react";

import { Navigate, Link } from "react-router-dom";
import { API_BASE_URL } from "./utils/api";

const Header = ({
  selectedJob,
  onJobChange,
  jobs,
  onMenuItemClick,
  currentView,
  onLogout,
  company,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { id: "dashboard", label: "Dashboard" },
    { id: "createJob", label: "Create Job", Icon: Plus },
    { id: "questionBuilder", label: "Question Builder" },
    { id: "jobseekerList", label: "Jobseekers" },
    { id: "jobListing", label: "Job Listing" },
    { id: "swimLanes", label: "Swim Lanes" },
    { id: "workerManagement", label: "Worker Management" },
    { id: "billing", label: "Billing" },
    { id: "companysettings", label: "Company Settings" },
  ];

  return (
    <header className="bg-white text-gray-700 py-4 px-4 md:px-10 border-b border-gray-200 flex justify-between items-center w-full">
      <div className="flex items-center">
        <img
          className="w-32 mr-4 pr-2 cursor-pointer"
          src={
            company.logo
              ? `${API_BASE_URL}${company.logo?.replace(/^\//, "")}`
              : "./whoppit-logo.png"
          }
          alt="Whoppit"
          onClick={() => onMenuItemClick("dashboard")}
        />
        <h1 className="text-xl font-semibold hidden md:block">Recruitment</h1>
      </div>
      <div className="flex items-center gap-4">
        {/* Create Job Button (visible on larger screens)
        <button
          onClick={() => onMenuItemClick("createJob")}
          className="hidden md:flex items-center gap-2 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors"
        >
          <Plus size={20} />
          Create Job
        </button>

        */}
        {currentView !== "create-job" && (
          <Link
            to="/create-job"
            className={`hidden md:flex items-center gap-2 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors sm:text-sm`}
          >
            <Plus size={20} />
            Create Job
          </Link>
        )}

        {/* Job Selection Dropdown */}
        {currentView !== "create-job" && jobs.length > 0 && (
          <div className="relative mr-2 md:mr-4">
            <Briefcase
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <select
              value={selectedJob}
              onChange={onJobChange}
              className="pl-10 pr-10 py-2 border-2 border-blue-500 rounded-md text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none cursor-pointer text-sm md:text-base"
            >
              {jobs.map((job) => (
                <option key={job._id} value={job._id}>
                  {job.jobReference}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <ChevronDown size={20} />
            </div>
          </div>
        )}

        {/* Menu Button */}
        <div className="relative">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-700 hover:text-gray-900 focus:outline-none"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          {isMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
              {menuItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => {
                    onMenuItemClick(item.id);
                    setIsMenuOpen(false);
                  }}
                  className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${currentView === item.id ? "bg-gray-100" : ""
                    }`}
                >
                  {item.Icon && (
                    <item.Icon size={16} className="inline-block mr-2" />
                  )}
                  {item.label}
                </button>
              ))}
              <div className="border-t border-gray-200 my-1"></div>
              <button
                onClick={() => {
                  onLogout();
                  setIsMenuOpen(false);
                }}
                className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
