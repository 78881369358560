import { useState, useEffect, useCallback } from "react";
import { checkForToken } from "../utils/chat";
import defaultQuestions from "../defaultQuestions.json";
import defaultOnBoardingQuestions from "../defaultOnBoardingQuestions.json";
import defaultJobSeekerObBoardingQuestions from "../defaultJobSeekerObBoardingQuestions.json";
import { assessmentQuestionnaire } from "../utils/chat";

export const useQuestionLoader = (userType) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [loadedMessages, setLoadedMessages] = useState(null);
  const [loadedQuestions, setLoadedQuestions] = useState(null);
  const [initialQuestion, setInitialQuestion] = useState(null);

  const fetchOnBoardingQuestionsFromAPI = async (usertype) => {
    const searchParams = new URLSearchParams(window.location.search);
    const job = searchParams.get("job");
    const jobseeker = searchParams.get("j");
    await new Promise((resolve) => setTimeout(resolve, 500));

    const user = usertype === "c" ? "employer" : "jobseeker";
    let questions = [];

    if (usertype === "c") {
      questions = defaultOnBoardingQuestions.onboardingQuestions[user];
    } else if (usertype === "j") {
      questions = defaultJobSeekerObBoardingQuestions.onboardingQuestions[user];
    } else if (usertype === "a") {
      questions = await assessmentQuestionnaire(job);
    }
    return questions;
  };

  const loadQuestions = useCallback(async () => {
    setIsLoading(true);
    try {
      let questions;
      questions = await fetchOnBoardingQuestionsFromAPI(userType);

      let defaultMessage = {
        text:
          userType === "j"
            ? "Welcome! Let's begin with the Registration process."
            : "Welcome! Let's begin the interview.",
        sender: "bot",
      };

      if (questions.length > 0) {
        const firstQuestion = {
          text: questions[0].question_text,
          sender: "bot",
          isQuestion: true,
          required: questions[0].required,
        };

        setLoadedMessages([defaultMessage, firstQuestion]);
        setLoadedQuestions(questions);
        setInitialQuestion(questions[0]);
      } else {
        setLoadedMessages([defaultMessage]);
        setLoadedQuestions([]);
        setInitialQuestion(null);
      }
    } catch (error) {
      console.error("Failed to fetch questions:", error);
      setLoadedMessages([
        {
          text: "Sorry, we couldn't load the questions. Please try again later.",
          sender: "bot",
        },
      ]);
      setLoadedQuestions([]);
      setInitialQuestion(null);
    } finally {
      setIsLoading(false);
    }
  }, [userType]);

  useEffect(() => {
    const validateAndLoad = async () => {
      try {
        /* to-do remove after testing */
        const valid = await checkForToken("assessment");
        if (valid) {
          await loadQuestions();
          setIsTokenValid(true);
        } else {
          setLoadedMessages([
            { text: "Link is Expired or Used", sender: "bot" },
          ]);
          setIsTokenValid(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setIsTokenValid(false);
        setIsLoading(false);
      }
    };

    validateAndLoad();
  }, [loadQuestions]);

  return {
    isLoading,
    isTokenValid,
    loadedMessages,
    loadedQuestions,
    initialQuestion,
  };
};
