import React from "react";
import Select from "react-select";

const JobseekerStatusMultiSelect = ({ onChange }) => {
  const options = [
    {
      label: "Statuses",
      options: [
        { value: "Applied", label: "Applied" },
        { value: "Interviewed", label: "Interview" },
        { value: "Interview Booked", label: "Interview Booked" },
        { value: "Hired", label: "Hired" },
        { value: "Rejected", label: "Rejected" },
      ],
    },
    {
      label: "Questionnaire Statuses",
      options: [
        { value: "not_started", label: "Not Started" },
        { value: "in_progress", label: "In Progress" },
        { value: "completed", label: "Completed" },
      ],
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "0.375rem",
      minHeight: "42px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#D1D5DB",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#3B82F6"
        : state.isFocused
          ? "#F3F4F6"
          : "white",
      color: state.isSelected ? "white" : "#374151",
      "&:active": {
        backgroundColor: "#2563EB",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: "#6B7280",
      fontSize: "0.875rem",
      fontWeight: "600",
      textTransform: "none",
      padding: "8px 12px",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#E5E7EB",
      borderRadius: "0.25rem",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#374151",
      padding: "2px 8px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#6B7280",
      "&:hover": {
        backgroundColor: "#D1D5DB",
        color: "#374151",
      },
    }),
  };

  return (
    <div className="w-60">
      <Select
        isMulti
        options={options}
        onChange={onChange}
        styles={customStyles}
        className=""
        placeholder="Filter by Status"
        closeMenuOnSelect={false}
        isClearable
      />
    </div>
  );
};

export default JobseekerStatusMultiSelect;
