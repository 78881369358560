import React, { useState, useRef, useEffect, useCallback } from "react";
import { Loader2 } from "lucide-react";
import { api, API_BASE_URL } from "./utils/api";
import { getNameInitials, calculateMessageSegments } from "./utils";
import { Button } from "./components/ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/Card";
import { Badge } from "./components/ui/Badge";
import { Input } from "./components/ui/Input";
import { Label } from "./components/ui/Label";
import { Textarea } from "./components/ui/Textarea";
import Avatar, { AvatarImage, AvatarFallback } from "./components/ui/Avatar";
import ScrollArea from "./components/ui/Scroll-area";
import {
  MessageSquare,
  Paperclip,
  Mail,
  PhoneCall,
  MessageCircle,
} from "lucide-react";
import Select from "react-select";
import { uploadMessageAttachments } from "./utils/upload";

const AttachmentList = ({ attachments }) => {
  if (!Array.isArray(attachments) || attachments.length === 0) {
    return null;
  }

  return (
    <div>
      {attachments.map((attachment, index) => (
        <div
          key={index}
          className="flex items-center mt-2 text-sm text-blue-600 hover:text-blue-700"
        >
          <Paperclip className="h-4 w-4 mr-1" />
          <a href={attachment} target="_blank" className="hover:underline">
            {attachment.split("/").pop().substring(0, 50)}
          </a>
        </div>
      ))}
    </div>
  );
};

const MessageInterface = ({
  worker,
  companyUser,
  initialJobseekers,
}) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef(null);
  const [userMessage, setUserMessage] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [messageSegments, setMessageSegments] = useState(0);
  // Update initial state to match Select component format
  const [selectedRecipient, setSelectedRecipient] = useState(
    worker.map((jobseeker) => ({
      id: jobseeker.id,
      name: jobseeker.name,
      value: jobseeker.id, // Add this for Select compatibility
      label: jobseeker.name, // Add this for Select compatibility
    })),
  );
  const [attachments, setAttachements] = useState([]);

  const loadOldMessage = useCallback(async () => {
    // Only load messages if there's exactly one recipient
    if (!selectedRecipient || selectedRecipient.length !== 1) {
      setMessages([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const jobseekerId = selectedRecipient[0].id;
      const companyUserId = companyUser.id;
      const response = await api.get(
        `/api/messages/jobseeker/${jobseekerId}/${companyUserId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      setMessages([]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedRecipient, companyUser]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    loadOldMessage();
  }, [selectedRecipient, companyUser]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleMessageSent = async () => {
    if (!userMessage.trim() || isSending) return;
    if (selectedRecipient.length === 0) return;

    setIsSending(true);
    const company_user = companyUser.id;
    const company_user_name = companyUser.name;

    try {
      let attachment = [];
      if (attachments.length > 0) {
        attachment = await uploadMessageAttachments(attachments);
      }
      const message = {
        recipient: selectedRecipient,
        sender_name: company_user_name,
        sender: company_user,
        content: userMessage,
        sender_type: "company_user",
        attachments: attachment,
      };
      await api.post("/api/messages", message);
      setMessageValue("");
      setUserMessage("");
      setMessageSegments(0);
      await loadOldMessage();
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      setIsSending(false);
    }
  };

  const handleTextAreaChange = (e) => {
    setUserMessage(e.target.value);
    setMessageValue(e.target.value);
    const segments = calculateMessageSegments(e.target.value);
    setMessageSegments(segments);
  };

  const MessageBubble = ({ message, isCompanyUser }) => (
    <div
      className={`flex items-end gap-2 mb-4 ${isCompanyUser ? "flex-row-reverse" : "flex-row"}`}
    >
      <div className="w-8 h-8 rounded-full bg-blue-100 flex-shrink-0 flex items-center justify-center text-blue-600 text-sm font-medium">
        {getNameInitials(message.sender_name)}
      </div>
      <div
        className={`max-w-[70%] break-words ${
          isCompanyUser
            ? "bg-blue-600 text-white rounded-t-lg rounded-bl-lg"
            : "bg-gray-100 text-gray-800 rounded-t-lg rounded-br-lg"
        } px-4 py-2 shadow-sm`}
      >
        {message.content}
      </div>
    </div>
  );

  const messageIcon = (type) => {
    switch (type.toLowerCase()) {
      case "sms":
        return <MessageSquare className="h-4 w-4 text-gray-600" />;
      case "whatsapp":
        return <MessageCircle className="h-4 w-4 text-gray-600" />;
      case "email":
        return <Mail className="h-4 w-4 text-gray-600" />;
      default:
        return <MessageSquare className="h-4 w-4 text-gray-600" />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        <div className="flex items-center gap-2 text-gray-600">
          <Loader2 className="w-5 h-5 animate-spin" />
          <span>Loading messages...</span>
        </div>
      </div>
    );
  }

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      "&:hover": {
        borderColor: "#9ca3af",
      },
      boxShadow: state.isFocused ? "0 0 0 1px #6366f1" : "none",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#6366f1" // indigo-500 for selected
        : isFocused
          ? "#e0e7ff" // indigo-100 for hover
          : null,
      color: isSelected ? "white" : "#374151",
      ":active": {
        backgroundColor: "#6366f1", // indigo-500
      },
      ":hover": {
        backgroundColor: "#6366f1", // indigo-500
        color: "white",
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#e0e7ff", // indigo-100
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#201e61", // indigo-600
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ":hover": {
        backgroundColor: "#ef4444",
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
  };

  const handleSelectedJobseekers = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectedRecipient([]);
      return;
    }

    const jobseekerDetails = selectedOptions.map((option) => ({
      id: option.value,
      name: option.label,
      value: option.value,
      label: option.label,
    }));
    setSelectedRecipient(jobseekerDetails);
  };

  const handleAttachmentAdd = (e) => {
    const files = e.target.files;
    setAttachements(files);
  };

  return (
    <div className="flex min-h-[600px] bg-white p-4">
      {/* left - Messages Timeline */}
      <div className="flex-grow flex flex-col h-full">
        <Card className="flex-grow overflow-hidden">
          <CardHeader className="border-b p-4">
            <CardTitle className="text-lg">Messages</CardTitle>
          </CardHeader>
          <CardContent className="p-0 h-full">
            <ScrollArea className="h-[500px]">
              <div className="p-4 space-y-4">
                {messages &&
                  messages.map((comm) => (
                    <div
                      key={comm.id}
                      className={`flex ${
                        comm.source === "Worker"
                          ? "justify-end"
                          : "justify-start"
                      }`}
                    >
                      <div
                        className={`max-w-[80%] p-4 rounded-lg ${
                          comm.source === "System"
                            ? "bg-gray-50 border border-gray-100"
                            : comm.source === "Recruiter"
                              ? "bg-blue-50 border border-blue-100"
                              : "bg-green-50 border border-green-100"
                        }`}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            {messageIcon(comm.type)}
                          </div>
                          <span className="ml-5 text-xs text-gray-500">
                            {new Date(comm.sentAt).toLocaleString()}
                          </span>
                        </div>
                        <p className="text-sm text-gray-700 mb-2">
                          {comm.content}
                        </p>
                        {comm.status && (
                          <Badge variant="outline" className="mb-2">
                            {comm.status}
                          </Badge>
                        )}
                        <AttachmentList attachments={comm.attachments} />
                      </div>
                    </div>
                  ))}
                {messages.length === 0 && (
                  <div className="text-center text-gray-500">
                    {selectedRecipient.length > 1
                      ? `Select single jobseeker to load messages`
                      : `No messages found`}
                  </div>
                )}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      {/* Right Sidebar - New Message Form */}
      <div className="w-80 flex-shrink-0 ml-4">
        <Card className="h-full">
          <CardHeader className="border-b p-4">
            <CardTitle className="text-lg flex items-center">
              <MessageSquare className="h-5 w-5 text-blue-600 mr-2" />
              New Message
            </CardTitle>
          </CardHeader>
          <CardContent className="p-4">
            <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm">Jobseekers</label>
                <Select
                  value={selectedRecipient}
                  isMulti={true}
                  name="colors"
                  options={initialJobseekers.map((jobseeker) => ({
                    value: jobseeker._id,
                    label: jobseeker.name,
                  }))}
                  className="w-full basic-multi-select"
                  styles={customStyles}
                  onChange={handleSelectedJobseekers}
                  placeholder="Select Jobseekers"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="message">Message</Label>
                <Textarea
                  id="message"
                  value={messageValue}
                  placeholder="Type your message..."
                  className="h-24"
                  onChange={handleTextAreaChange}
                />
                <p className="italic text-xs">
                  Message Segments: {messageSegments}
                </p>
              </div>

              <div className="space-y-2">
                <Label htmlFor="attachment">Attachment</Label>
                <Input
                  id="attachment"
                  type="file"
                  className="cursor-pointer block"
                  style={{ width: "80%" }}
                  onChange={handleAttachmentAdd}
                  multiple={true}
                  accept={`image/*, .doc, .docx, .pdf, .xlx, .xlsx`}
                />
              </div>

              <Button
                onClick={handleMessageSent}
                disabled={!userMessage.trim() || isSending}
                className="w-full bg-blue-600 hover:bg-blue-700 !w-3xs"
              >
                {isSending ? (
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                ) : (
                  <MessageSquare className="h-4 w-4 mr-2" />
                )}
                Send Message
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default MessageInterface;
