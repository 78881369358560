// hooks/useChatState.js
import { useState, useCallback, useEffect } from "react";
import {
  saveResponse,
  createConversation,
  endConversation,
} from "../utils/chat";
import { uploadFiles } from "../utils";
import evaluateCondition from "../utils/followUpQuestionHandler";
import { AnswerTypes } from "../constants";
import { useNotification } from "../CustomNotificationSystem";

export const useChatState = (userType) => {
  const [messages, setMessages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [questionQueue, setQuestionQueue] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [timeTaken, setTimeTaken] = useState(new Date());

  const notify = useNotification();
  // Initialize conversation
  useEffect(() => {
    const initializeConversation = async () => {
      try {
        const user_type =
          userType === "j" || userType === "a" ? "jobseeker" : "company_user";
        const response = await createConversation(user_type);
        setConversation(response.data._id);
      } catch (error) {
        console.error("Error creating conversation:", error);
      }
    };

    initializeConversation();
  }, [userType]);

  const handleFileUpload = async () => {
    if (uploadedFiles.length === 0) return [];

    try {
      return await uploadFiles(uploadedFiles, "chat");
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  };

  const handleValidation = useCallback(() => {
    const type = currentQuestion?.answer_type.toLowerCase() ?? null;
    let validate = false;
    let validationError;
    switch (type) {
      case AnswerTypes.FILE.toLowerCase():
      case AnswerTypes.IMAGE.toLowerCase():
        const allowedFileSize = currentQuestion?.question?.maxFileSize ?? null; // MB
        let allowedFileTypes =
          currentQuestion?.question?.allowedFileTypes ?? null;
        if (!allowedFileSize && AnswerTypes.IMAGE.toLowerCase() === type) {
          allowedFileTypes = ".jpg,.jpeg,.png";
        }
        if (uploadedFiles.length > 0) {
          validate = true; // default set to true if no validation is passed by user
          uploadedFiles.forEach((file) => {
            if (allowedFileTypes) {
              // check file type
              const filename = file.name;
              const fileExtension = filename.split(".").pop().toLowerCase();
              // Create an array of allowed extensions
              const allowed = allowedFileTypes
                .split(",")
                .map((ext) => ext.trim().replace(".", "").toLowerCase());

              // Check if the file extension is in the allowed list
              validate = allowed.includes(fileExtension);
              if (!validate) {
                validationError = `File is not supported, please upload only ${allowedFileTypes}`;
              }
            }
            if (allowedFileSize) {
              //check file size
              const fileSizeMB = file.size / (1024 * 1024);
              if (fileSizeMB > allowedFileSize) {
                validate = false;
                validationError = `File size should be under ${allowedFileSize}MB`;
              }
            }
          });
        }
        break;
      case AnswerTypes.DATE.toLowerCase():
        const minAllowedDate = currentQuestion?.question?.minDate ?? null;
        const maxAllowedDate = currentQuestion?.question?.maxDate ?? null;
        validate = true;
        if (minAllowedDate && maxAllowedDate) {
          const minDate = new Date(minAllowedDate);
          const maxDate = new Date(maxAllowedDate);
          validationError = `Date should be between ${minDate.toLocaleString()} and ${maxDate.toLocaleString()}`;

          const answerDate = new Date(userAnswer);
          if (answerDate >= minDate && answerDate <= maxDate) {
            validate = true;
          } else {
            validate = true;
          }
        }
        break;
      case AnswerTypes.DATE_TIME.toLowerCase():
        const minAllowedDateTime = currentQuestion?.question?.minDate ?? null;
        const maxAllowedDateTime = currentQuestion?.question?.maxDate ?? null;
        validate = true;
        if (minAllowedDateTime && maxAllowedDateTime) {
          const minDateTime = new Date(minAllowedDateTime);
          const maxDateTime = new Date(maxAllowedDateTime);
          validationError = `Date should be between ${minDateTime.toLocaleString()} and ${maxDateTime.toLocaleString()}`;

          const answerDate = new Date(userAnswer);
          if (answerDate >= minDateTime && answerDate <= maxDateTime) {
            validate = true;
          } else {
            validate = false;
          }
        }
        break;
      case AnswerTypes.NUMERIC.toLowerCase():
        const maxValue = currentQuestion.question.maxValue ?? null;
        const minValue = currentQuestion.question.minValue ?? 0;
        validate = true;
        if (maxValue !== null) {
          try {
            if (
              parseFloat(userAnswer) < parseFloat(minValue) ||
              parseFloat(userAnswer) > parseFloat(maxValue)
            ) {
              validate = false;
              validationError = `Entered value should be between ${maxValue} and ${minValue}`;
            }
          } catch (error) { }
        }
        break;
      case AnswerTypes.EMAIL.toLowerCase():
        const allowedDomains = currentQuestion?.question?.allowedDomains ?? "";
        const userDomain = userAnswer.split("@").pop().toLowerCase().trim();
        validate = true;

        if (allowedDomains !== "") {
          const domain = allowedDomains.split(",").filter((domain) => {
            if (domain === userDomain) return true;
            return false;
          });

          if (domain.length === 0) {
            validationError = `Allowed domains are ${allowedDomains}`;
            validate = false;
          }
        }
        break;
      case AnswerTypes.PHONE.toLowerCase():
        const pattern = currentQuestion.question.phoneFormat.toLowerCase();
        validate = true;
        if (pattern !== "") {
          const regexPattern = pattern
            .replace(/[^x\-]/g, "") // Remove any character that's not 'x' or '-'
            .replace(/x/g, "\\d"); // Replace x with \d

          const regex = new RegExp(`^${regexPattern}$`);
          validate = regex.test(userAnswer);
          validationError = `Please check phone number format. Should be - ${currentQuestion.question.phoneFormat.toLowerCase()}`;
        }
        break;
      case AnswerTypes.URL.toLowerCase():
        const allowedProtocols =
          currentQuestion?.question?.allowedProtocols ?? null;
        validationError = `Please enter valid URL only, URL must have a prefix with ${allowedProtocols}`;
        try {
          if (allowedProtocols !== null) {
            // Convert string of protocols into an array if needed
            const protocols = Array.isArray(allowedProtocols)
              ? allowedProtocols
              : allowedProtocols.split(/[\s,]+/);

            // Create URL object to parse the protocol
            const urlObject = new URL(userAnswer);

            // Remove ':' from the protocol string
            const urlProtocol = urlObject.protocol.replace(":", "");
            // Check if the URL's protocol is in allowed protocols
            return protocols.includes(urlProtocol);
          } else {
            validate = false;
          }
        } catch (error) {
          // Return false if URL is invalid
          validate = false;
        }
        break;
      default:
        validate = true;
    }
    if (!validate) {
      notify(validationError || "Validation failed", "error");
    }
    return validate;
  }, [currentQuestion, userAnswer, uploadedFiles, notify]);

  const handleFollowUpQuestions = useCallback((currentQ, answer, queue) => {
    // Guard against undefined/null values
    if (!currentQ || !queue || !Array.isArray(queue)) {
      return [];
    }

    // Create a copy of the queue
    let newQueue = [...queue];

    // Check if there are follow-up questions
    if (
      currentQ.follow_up_questions &&
      currentQ.follow_up_questions.length > 0
    ) {
      // Find the current question's position
      const currentIndex = newQueue.findIndex((q) => q._id === currentQ._id);

      // Find matching follow-ups
      const matchingFollowUps = currentQ.follow_up_questions.filter((fu) =>
        evaluateCondition(fu.condition, fu.condition_value, answer),
      );

      // If we have matching follow-ups, insert them after current question
      if (matchingFollowUps.length > 0) {
        newQueue.splice(
          currentIndex + 1,
          0,
          ...matchingFollowUps.map((fu) => fu.question),
        );
      }
    }
    // Remove the current question and return the updated queue
    return newQueue.filter((q) => q.id !== currentQ.id);
  }, []);

  const progressToNextQuestion = useCallback((updatedQueue, conversation) => {
    if (!updatedQueue?.length) {
      endQuestionnaire(conversation);
      return;
    }

    const nextQuestion = updatedQueue[0];
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
      setMessages((prev) => [
        ...prev,
        {
          text: nextQuestion.question_text,
          sender: "bot",
          isQuestion: true,
          required: nextQuestion.required,
        },
      ]);
    } else {
      endQuestionnaire(conversation);
    }
  }, []);

  const saveMessageAndProgress = async (fileUrls) => {
    const manual_category = ["avatar", "reference"]; // those questions which we have added staticly and not by the company user
    console.log(currentQuestion);

    /* const question = manual_category.includes(currentQuestion.category)
      ? currentQuestion
      : currentQuestion.question;
    */
    const question = currentQuestion.question;

    let answer_text = "";
    if (
      question.answer_type.toLowerCase() ===
      AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER.toLowerCase()
    ) {
      answer_text = userAnswer.join(", ");
    } else {
      answer_text = userAnswer.trim();
    }

    /* handling the promt_file_upload */
    if (!question.promt_file_upload || question.promt_file_upload === false) {
      question.promt_file_upload = null;
    } else if (question?.promt_file_upload === true) {
      question.promt_file_upload = fileUrls.map((file) => ({
        name: file.name,
        file: file.url,
        fileType: file.mimetype,
      }));
    }
    /* handling the promt_file_upload */
    const newMessage = {
      text: answer_text,
      sender: "user",
      files: fileUrls.map((file) => ({
        name: file.name,
        file: file.url,
        fileType: file.mimetype,
      })),
      user: {},
    };

    const searchParams = new URLSearchParams(window.location.search);
    const queryJobseeker = searchParams.get("j");
    if (queryJobseeker) {
      newMessage.user.userId = queryJobseeker;
    }

    setMessages((prev) => [...prev, newMessage]);
    setUserAnswer("");
    setUploadedFiles([]);
    await saveResponse(
      conversation,
      question,
      newMessage,
      currentQuestion.answer_type.toLowerCase(),
    );

    // Handle follow-ups and progress to next question
    const updatedQueue = handleFollowUpQuestions(
      currentQuestion,
      userAnswer,
      questionQueue,
    );
    setQuestionQueue(updatedQueue);
    progressToNextQuestion(updatedQueue, conversation);
  };

  const isAnswerValid = () => {
    if (!userAnswer) return false;

    if (
      currentQuestion?.answer_type?.toLowerCase() ===
      AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER.toLowerCase()
    ) {
      return Array.isArray(userAnswer) ? userAnswer.length > 0 : false;
    }

    return typeof userAnswer === "string" ? userAnswer.trim().length > 0 : true;
  };

  const handleSendAnswer = async () => {
    if ((!isAnswerValid() && uploadedFiles.length === 0) || isUploading) {
      return;
    }
    setIsUploading(true);
    try {
      if (handleValidation()) {
        const fileUrls = await handleFileUpload();
        await saveMessageAndProgress(fileUrls);
      } else {
        throw new Error("validation falied");
      }
    } catch (error) {
      console.error("Error handling answer:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const endQuestionnaire = useCallback(
    (conversation) => {
      const endMessage = {
        text:
          userType === "a"
            ? "Thank you for completing the questionnaire. Your responses will be reviewed shortly."
            : "Thank you for completing the registration. We will send you an email shortly with further details.",
        sender: "bot",
      };

      setMessages((prev) => [...prev, endMessage]);

      const total_time = new Date() - timeTaken;
      setTimeTaken(total_time);
      const endMode = userType === "j" ? "jobseeker_registation" : "assessment";

      if (conversation) {
        endConversation(conversation, endMode, total_time);
      }

      setCurrentQuestion(null);
      setQuestionQueue([]);
    },
    [userType, timeTaken],
  );

  return {
    messages,
    setMessages,
    currentQuestion,
    setCurrentQuestion,
    userAnswer,
    setUserAnswer,
    questionQueue,
    setQuestionQueue,
    answeredQuestions,
    setAnsweredQuestions,
    skippedQuestions,
    setSkippedQuestions,
    conversation,
    setConversation,
    uploadedFiles,
    setUploadedFiles,
    isUploading,
    setIsUploading,
    timeTaken,
    setTimeTaken,
    handleSendAnswer,
  };
};
