import React, { useMemo, useState } from "react";
import { ChevronRight } from "lucide-react";
import { getNameInitials, sortedJobseekers } from "./utils";
import { API_BASE_URL } from "./utils/api";
import { Tooltip as ToolTipHtml } from "react-tooltip";
import {
  capitalizeWords,
} from "./utils";

const RecentApplicants = ({ jobseekers, onViewJobseeker }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  // Sort jobseekers by application date (most recent first) and take the top 5
  const recentApplicants = useMemo(() => {
    const sortedJobseekersList = sortedJobseekers(jobseekers);
    return sortedJobseekersList.slice(0, 6); // sending top 6 candidates only
  }, [jobseekers]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">Recent Applicants</h3>
      <ul className="space-y-4">
        {recentApplicants.length > 0 ? (
          recentApplicants.map((applicant) => (
            <li
              key={applicant._id}
              className="flex items-center justify-between p-2 hover:bg-gray-100 rounded cursor-pointer"
              onClick={() => onViewJobseeker(applicant._id)}
            >
              <div className="flex items-center">
                {applicant.avatar ? (
                  <img
                    src={`${API_BASE_URL}${applicant.avatar.replace(/^\//, "")}`}
                    alt={applicant.name}
                    className="w-10 h-10 rounded-full mr-3 object-cover"
                  />
                ) : (
                  <span className="w-10 h-10 bg-indigo-500 text-white rounded-full flex items-center justify-center font-bold text-sm mr-4">
                    {getNameInitials(applicant.name)}
                  </span>
                )}
                <div>
                  <h4 className="font-medium">{capitalizeWords(applicant.name)}</h4>
                  <p className="text-sm text-gray-600">
                    {applicant.jobTitle} • {applicant.location}
                  </p>
                  <p className="text-sm text-gray-600">
                    {formatDate(applicant.createdAt)}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <span
                  className={`px-2 py-1 rounded-full text-xs ${getStatusColor(applicant.applicationStatus)}`}
                >
                  {applicant.applicationStatus}
                </span>
                <span
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseOut={() => setTooltipOpen(false)}
                  data-tooltip-id="questionnaireStatusTooltip"
                  data-tooltip-content={`Questionnaire: ${applicant.detailedInfo.questionnaireResponsesCompletedBy ? applicant.detailedInfo.questionnaireResponsesCompletedBy : ""} (${applicant.questionnaireCompletionRate}%)`}
                  className={`px-2 py-1 rounded-full text-xs ${getQuestionnaireStatusColor(applicant.questionnaireStatus)}`}
                >
                  {applicant.questionnaireStatus
                    .replace("_", " ")
                    .charAt(0)
                    .toUpperCase() +
                    applicant.questionnaireStatus.replace("_", " ").slice(1)}
                </span>
                {applicant.questionnaireCompletionRate ? (
                  <ToolTipHtml
                    id="questionnaireStatusTooltip"
                    isOpen={tooltipOpen}
                  />
                ) : (
                  ""
                )}

                <div
                  className={`w-10 h-10 rounded-full border-2 flex items-center justify-center font-semibold ${getScoreColor(applicant.score)}`}
                >
                  {applicant.score ?? 0}
                </div>
                <ChevronRight size={20} className="text-gray-400 ml-2" />
              </div>
            </li>
          ))
        ) : (
          <li>No recent applicants</li>
        )}
      </ul>
    </div>
  );
};

// Helper function to get color based on application status
const getStatusColor = (status) => {
  if (!status) status = "";

  switch (status.toLowerCase()) {
    case "applied":
      return "text-blue-600";
    case "shortlisted":
      return "text-yellow-600";
    case "interview booked":
      return "text-purple-600";
    case "interviewed":
      return "text-orange-600";
    case "hired":
      return "text-green-600";
    default:
      return "text-gray-600";
  }
};

// Helper function to get color based on score
const getScoreColor = (score) => {
  if (score >= 80) return "border-green-500 text-green-700";
  if (score >= 60) return "border-yellow-500 text-yellow-700";
  return "border-red-500 text-red-700";
};

// Helper function to get color based on questionnaire status
const getQuestionnaireStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "not_started":
      return "bg-gray-200 text-gray-800";
    case "in_progress":
      return "bg-yellow-200 text-yellow-800";
    case "completed":
      return "bg-green-200 text-green-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

export default RecentApplicants;
