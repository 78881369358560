import React from "react";
import { X, Tag } from "lucide-react";

const Tags = ({
  text,
  onDelete,
  size = "sm", // sm, md, lg
}) => {
  const sizeClasses = {
    sm: "text-xs py-0.5 px-2 gap-1",
    md: "text-sm py-1 px-3 gap-1.5",
    lg: "text-base py-1.5 px-4 gap-2",
  };

  const iconSizes = {
    sm: 14,
    md: 16,
    lg: 18,
  };

  return (
    <div
      className={`
      inline-flex items-center
      ${sizeClasses[size]}
      bg-gray-200 text-black
      rounded-full font-medium
      transition-colors
    `}
    >
      <Tag size={iconSizes[size]} />
      {text}
      <button
        onClick={onDelete}
        className="inline-flex items-center justify-center hover:bg-red-300 rounded-full p-0.5"
        aria-label="Remove tag"
      >
        <X size={iconSizes[size]} className="text-black" />
      </button>
    </div>
  );
};

export default Tags;
