import { api } from "../utils/api";
import { useState, useCallback } from "react";

class TagService {
  // Create a new tag

  static async createTag(tagData) {
    try {
      const response = await api.post("/api/tags", tagData);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Get all tags
  static async getAllTags() {
    try {
      const response = await api.get("/api/tags");
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Get tags by user ID
  static async getTagsByUser(userId) {
    try {
      const response = await api.get(`/api/tags/user/${userId}`);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Get tags by company ID
  static async getTagsByCompany(companyId) {
    try {
      const response = await api.get(`/api/tags/company/${companyId}`);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Get a specific tag by ID
  static async getTagById(id) {
    try {
      const response = await api.get(`/api/tags/${id}`);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Update a tag
  static async updateTag(id, tagData) {
    try {
      const response = await api.put(`/api/tags/${id}`, tagData);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Delete a tag
  static async deleteTag(id) {
    try {
      const response = await api.delete(`/api/tags/${id}`);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  // Error handler
  static handleError(error) {
    console.log(error);
    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";

    throw new Error(errorMessage);
  }

  static async createJobseekerTag(tagData) {
    try {
      const response = await api.post("/api/jobseekers/tag", tagData);
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }

  static async deleteJobseekerTag(tagId) {
    try {
      const response = await api.delete(
        `/api/jobseekers/tag/${tagId}`,
      );
      return response.data;
    } catch (error) {
      TagService.handleError(error);
    }
  }
}

// Custom hook for tag operations
export const useTag = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const executeTagOperation = useCallback(async (operation, ...params) => {
    setLoading(true);
    setError(null);
    try {
      const result = await operation(...params);
      setLoading(false);
      return result;
    } catch (err) {
      setError(err.message);
      setLoading(false);
      throw err;
    }
  }, []);

  const createTag = useCallback(
    (tagData) => {
      return executeTagOperation(TagService.createTag, tagData);
    },
    [executeTagOperation],
  );

  const getAllTags = useCallback(() => {
    return executeTagOperation(TagService.getAllTags);
  }, [executeTagOperation]);

  const getTagsByUser = useCallback(
    (userId) => {
      return executeTagOperation(TagService.getTagsByUser, userId);
    },
    [executeTagOperation],
  );

  const getTagsByCompany = useCallback(
    (companyId) => {
      return executeTagOperation(TagService.getTagsByCompany, companyId);
    },
    [executeTagOperation],
  );

  const getTagById = useCallback(
    (id) => {
      return executeTagOperation(TagService.getTagById, id);
    },
    [executeTagOperation],
  );

  const updateTag = useCallback(
    (id, tagData) => {
      return executeTagOperation(TagService.updateTag, id, tagData);
    },
    [executeTagOperation],
  );

  const deleteTag = useCallback(
    (id) => {
      return executeTagOperation(TagService.deleteTag, id);
    },
    [executeTagOperation],
  );

  const createJobseekerTag = useCallback((tagData) => {
    return executeTagOperation(TagService.createJobseekerTag, tagData);
  });

  const deleteJobseekerTag = useCallback((tagId) => {
    return executeTagOperation(
      TagService.deleteJobseekerTag,
      tagId,
    );
  });

  return {
    loading,
    error,
    createTag,
    getAllTags,
    getTagsByUser,
    getTagsByCompany,
    getTagById,
    updateTag,
    deleteTag,
    createJobseekerTag,
    deleteJobseekerTag,
  };
};
