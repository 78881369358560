import React, { useState, useEffect } from "react";
import { uploadCompanyLogo } from "./utils/upload";
import { useNotification } from "./CustomNotificationSystem";
import { API_BASE_URL } from "./utils/api";

const CompanySettings = ({ company, onSave, currentUser }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    industry: "",
    location: "",
    website: "",
    employeeCount: "",
    foundedYear: "",
    logo: "",
    contactEmail: "",
    contactPhone: "",
  });

  useEffect(() => {
    if (company) {
      setFormData(company);
    }
  }, [company]);

  const notify = useNotification();
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const setNestedValue = (obj, path, value) => {
    const parts = path.split(".");
    const last = parts.pop();
    const target = parts.reduce(
      (acc, part) => (acc[part] = acc[part] || {}),
      obj,
    );
    target[last] = value;
  };

  const handleCalendlyAuth = () => {
    const clientId = process.env.REACT_APP_CALENDLY_CLIENT_ID;
    const redirectUri = `${API_BASE_URL}callback/calendly`;
    //const scopes = "users.read users.write scheduling.read scheduling.write";
    const state = encodeURIComponent(currentUser.id); // You could also use a session token
    const authUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${state}`;
    window.open(authUrl, "_blank");
  };

  const handleInputChange = (path) => async (e) => {
    const updatedFormData = { ...formData };
    setNestedValue(updatedFormData, path, e.target.value);
    if (path === "logo") {
      const logo_details = await uploadCompanyLogo(e.target.files[0]);
      updatedFormData.logo = logo_details.url;
    }
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSave(formData);
    notify("Changes are saved", "success");
  };

  const inputClassName =
    "mt-1 block w-full rounded-lg border border-gray-200 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm px-4 py-3 hover:bg-white focus:bg-white transition-all duration-200 ease-in-out";

  const formSections = [
    {
      title: "Basic Information",
      fields: [
        { name: "name", label: "Company Name", type: "text", required: true },
        { name: "description", label: "Description", type: "textarea" },
        { name: "industry", label: "Industry", type: "text" },
        { name: "location", label: "Location", type: "text" },
      ],
    },
    {
      title: "Company Details",
      fields: [
        { name: "website", label: "Website", type: "url" },
        { name: "employeeCount", label: "Employee Count", type: "number" },
        { name: "foundedYear", label: "Founded Year", type: "number" },
      ],
    },
    {
      title: "Contact Information",
      fields: [
        { name: "contactEmail", label: "Contact Email", type: "email" },
        { name: "contactPhone", label: "Contact Phone", type: "tel" },
      ],
    },
    {
      title: "ATs Credentials",
      fields: [
        {
          name: "settings.ats_details.cvliv.cvlusername",
          label: "CV-Library Username",
          type: "text",
        },
        {
          name: "settings.ats_details.cvliv.cvlpassword",
          label: "CV-Library Password",
          type: "password",
        },
        {
          name: "settings.ats_details.cvliv.cvlemail",
          label: "CV-Library Email",
          type: "email",
        },
      ],
    },
  ];

  return (
    <div className="container mx-auto p-4 lg:p-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-indigo-800">Company Settings</h2>
        <button
          type="submit"
          form="company-form"
          className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Save Changes
        </button>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <form
          id="company-form"
          onSubmit={handleSubmit}
          className="divide-y divide-gray-200"
        >
          {formSections.map((section, sectionIndex) => (
            <div
              key={section.title}
              className="p-8 space-y-6 hover:bg-gray-50 transition-colors duration-200"
            >
              <h3 className="text-xl font-semibold text-indigo-700 mb-4">
                {section.title}
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-white p-6 rounded-lg">
                {section.fields.map((field) => (
                  <div
                    key={field.name}
                    className={field.type === "textarea" ? "md:col-span-2" : ""}
                  >
                    <label
                      htmlFor={field.name}
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {field.label}
                      {field.required && (
                        <span className="text-red-500 ml-1">*</span>
                      )}
                    </label>
                    {field.type === "textarea" ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={getNestedValue(formData, field.name) || ""}
                        onChange={handleInputChange(field.name)}
                        rows="4"
                        className={`${inputClassName} resize-none`}
                      />
                    ) : (
                      <input
                        id={field.name}
                        name={field.name}
                        type={field.type}
                        value={getNestedValue(formData, field.name) || ""}
                        onChange={handleInputChange(field.name)}
                        required={field.required}
                        className={inputClassName}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="p-6 bg-gray-50">
            <div className="md:col-span-2">
              <label
                htmlFor="logo"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Company Logo
              </label>
              <input
                id="logo"
                name="logo"
                type="file"
                onChange={handleInputChange("logo")}
                accept="image/*"
                className={`${inputClassName} file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100`}
              />
              {formData.logo && (
                <p className="mt-2 text-sm text-gray-500">
                  Selected file:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${API_BASE_URL}${formData.logo}?.replace(/^\//, "")}`}
                  >
                    {formData.logo}
                  </a>
                </p>
              )}
            </div>
          </div>

          <div
            key="calendly_auth"
            className="p-8 space-y-6 hover:bg-gray-50 transition-colors duration-200"
          >
            <h3 className="text-xl font-semibold text-indigo-700 mb-4">
              Calendly Authentication
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-white p-6 rounded-lg">
              <div key="calendly_auth_main" className="md:col-span-2">
                {!company.settings.calendly ? (
                  <button
                    type="button"
                    onClick={handleCalendlyAuth}
                    className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    <img src="./calendly-logo.png" alt="Calendly Logo" />
                    Authenticate Calendly
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    <img src="./calendly-logo.png" alt="Calendly Logo" />
                    Calendly Connected
                  </button>
                )}
                <em>
                  After connecting, you can use Calendly to schedule interviews
                  with jobseekers from jobseeker listing page.
                </em>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanySettings;
