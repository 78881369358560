// ScrollArea.js
import React from 'react';

const ScrollArea = ({ children, className = '', ...props }) => {
  return (
    <div
      className={`overflow-auto ${className}`}
      {...props}
      style={{ maxHeight: '400px' }} // Customize max height as needed
    >
      {children}
    </div>
  );
};

// Exporting ScrollArea as default
export default ScrollArea;
