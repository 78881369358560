import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { recentGraphTransactions } from "./utils/paymentHistory";
const BillingInformation = () => {
  const [chartData, setChartData] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);
  useEffect(() => {
    const getRecentGraphTransaction = async () => {
      try {
        const history = await recentGraphTransactions();
        setChartData(history.items ?? []);
        setTotalSpend(history.total);
      } catch (err) {}
    };
    getRecentGraphTransaction();
  }, []);
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">
        Monthly Billing Information
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            formatter={(value, name, props) => {
              const description = props.payload?.description || "";
              return [`${description} = £${value}`]; // Show amount and description on hover
            }}
          />
          <Legend
            formatter={(value) => {
              const service = chartData.find((item) => item.name === value);
              return service ? service.description : value;
            }}
          />
          <Bar dataKey="amount" fill="#8884d8" name="Cost per Service" />
        </BarChart>
      </ResponsiveContainer>
      <div className="mt-4 text-right">
        <span className="text-lg font-semibold">
          Total Spend: £{totalSpend}
        </span>
      </div>
    </div>
  );
};

export default BillingInformation;
