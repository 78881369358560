import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import DashBoardTiles from "./DashBoardTiles";
import RecentApplicants from "./RecentApplicants";
import BillingInformation from "./BillingInformation";
import StripeService from "./services/StripeService";
const Dashboard = ({ jobs, jobseekers, onNavigate, selectedJob }) => {
  // Handler for viewing a specific jobseeker
  const handleViewJobseeker = (jobseekerId) => {
    onNavigate("jobseekerList", jobseekerId);
  };

  const applicationStatusData = useMemo(() => {
    const statusCounts = jobseekers.reduce((acc, js) => {
      acc[js.applicationStatus] = (acc[js.applicationStatus] || 0) + 1;
      return acc;
    }, {});

    const colors = {
      Applied: "#aec6cf",
      Shortlisted: "#b2d8d8",
      "Interview Booked": "#cfcfc4",
      Interviewed: "#ffb347",
      Hired: "#c8e6c9",
    };

    const orderedStatuses = [
      "Applied",
      "Shortlisted",
      "Interview Booked",
      "Interviewed",
      "Hired",
    ];

    return orderedStatuses.map((status) => ({
      status,
      count: statusCounts[status] || 0,
      fill: colors[status] || "#8884d8",
    }));
  }, [jobseekers]);

  const stripeSubscriptionCheck = useCallback(async () => {
    const subscription = await StripeService.getSubscription();
    if (subscription.length === 0) {
      StripeService.createSubscription();
    }
  }, []);
  stripeSubscriptionCheck();
  // Prepare data for questionnaire status pie chart
  const questionnaireStatusData = useMemo(() => {
    const statusCounts = jobseekers.reduce((acc, js) => {
      const status = js.questionnaireStatus || "Not Started";
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(statusCounts).map(([status, value]) => ({
      name: status.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
      value,
    }));
  }, [jobseekers]);

  // Colors for pie chart
  const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#0088FE"];

  return (
    <div className="p-4 bg-gradient-to-br from-blue-50 to-indigo-50 min-h-screen lg:p-8">
      <DashBoardTiles selectedJob={selectedJob} />
      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Application Status Chart */}
        <ChartCard title="Application Status Distribution">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={applicationStatusData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis dataKey="status" tick={{ fill: "#4a5568" }} />
              <YAxis tick={{ fill: "#4a5568" }} />
              <Tooltip
                contentStyle={{ backgroundColor: "#fff", borderRadius: "8px" }}
              />
              <Legend wrapperStyle={{ paddingTop: "20px" }} />
              <Bar
                dataKey="count"
                isAnimationActive={true}
                animationDuration={1000}
              >
                {applicationStatusData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>

        {/* Recent Applicants */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <RecentApplicants
            jobseekers={jobseekers}
            onViewJobseeker={handleViewJobseeker}
          />
        </div>

        {/* Questionnaire Status Pie Chart */}
        <ChartCard title="Questionnaire Status">
          {questionnaireStatusData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={questionnaireStatusData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {questionnaireStatusData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            "No Data available"
          )}
        </ChartCard>

        {/* Billing Information */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <BillingInformation />
        </div>
      </div>
    </div>
  );
};

// ChartCard component for consistent chart styling
const ChartCard = ({ title, children }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-semibold mb-4 text-gray-800">{title}</h3>
    {children}
  </div>
);

export default Dashboard;
