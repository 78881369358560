import { api } from "./api";
import { jobQuestionnaire, jobseekerPendingDetails } from "../utils";
import defaultJobSeekerObBoardingQuestions from "../defaultJobSeekerObBoardingQuestions.json";

export const saveResponse = async (
  conversationId,
  question,
  response,
  messageType,
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const jtoken = searchParams.get("token");

  const token = localStorage.getItem("token");
  return await api.post(
    "/api/chat/message",
    {
      question,
      message: response,
      conversationId,
      messageType,
      jtoken,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
};

export const createConversation = async (type) => {
  const token = localStorage.getItem("token");
  return await api.post(
    "/api/chat/conversation",
    {
      participants: { role: type },
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
};

export const endConversation = async (
  conversation,
  type,
  total_time = null,
) => {
  return await api.put("/api/chat/end/conversation", {
    conversation,
    type,
    total_time,
  });
};

const transformQuestion = (question) => {
  // Base transformation for any question
  const baseTransform = {
    id: question._id,
    category: question.question_type.toLowerCase(),
    question: question,
    question_text: question.question_text,
    answer_type: question.answer_type?.toLowerCase() ?? "text",
    type: "text",
    required: true,
  };

  // Handle follow-up questions if they exist
  if (question.follow_up_questions && question.follow_up_questions.length > 0) {
    // Transform each follow-up question recursively
    const followUpQuestions = question.follow_up_questions.map((followUp) => ({
      id: followUp.id,
      condition: followUp.condition,
      condition_value: followUp.condition_value,
      question: transformQuestion(followUp.question), // Recursive call
    }));

    // Add follow-up questions to the base transformation
    return {
      ...baseTransform,
      follow_up_questions: followUpQuestions,
    };
  }

  // Return base transformation if no follow-up questions
  return baseTransform;
};

// Function to transform an array of questions
const transformQuestions = (questions) => {
  return questions.map((question) => transformQuestion(question));
};

// Example usage:
export const assessmentQuestionnaire = async (jobId) => {
  /*
  const pendingJobseekerDetails = await jobseekerPendingDetails(
    jobId,
    jobseeker,
  );
  */

  const pendingJobseekerDetails = [];

  const onboarding_question =
    defaultJobSeekerObBoardingQuestions.onboardingQuestions.jobseeker;
  let pending_details = onboarding_question.filter((element) => {
    if (pendingJobseekerDetails.indexOf(element.field) > -1) {
      return true;
    }
    return false;
  });

  const questions = await jobQuestionnaire(jobId);
  const assessment_questions = transformQuestions(questions);
  const formated_questions = [...pending_details, ...assessment_questions];

  const question_with_jobid = formated_questions.map((question) => {
    if (!question.jobId) {
      question.jobId = jobId;
    }
    return question;
  });
  return question_with_jobid;
};

export const checkForToken = async (type, token = false) => {
  if (!token) {
    const searchParams = new URLSearchParams(window.location.search);
    token = searchParams.get("token");
  }
  try {
    await api.post("/api/jobseekers/verify/token", {
      token,
      type,
    });
    return true;
  } catch (error) {
    return false;
  }
};
