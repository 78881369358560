import React, { useState, useEffect, useMemo } from "react";
import {
  Briefcase,
  Users,
  CheckCircle,
  CheckCircle2,
  PoundSterling,
  Star,
  ClipboardList,
  Clock,
  UserCheck,
  CalendarCheck,
} from "lucide-react";
import { getDashboardOverview, getJobOverview } from "./utils";

// Reusable component for each item within a box
const StatItem = ({ Icon, text, value, color, bgColor }) => (
  <div className="flex items-center">
    <div
      className={`mr-4 ${bgColor || "bg-blue-500"} text-white p-3 rounded-full`}
    >
      <Icon className={`h-5 w-5 ${color}`} />
    </div>
    <span className="text-gray-600">
      {text}
      <span className="font-bold block">{value}</span>
    </span>
  </div>
);

// Reusable component for each box
const StatsBox = ({ items }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 cursor-pointer">
    <div className="space-y-4">
      {items.map((item, index) => (
        <StatItem
          key={index}
          Icon={item.icon}
          text={item.text}
          value={item.value}
          color={item.color}
          bgColor={item.bgColor}
        />
      ))}
    </div>
  </div>
);

const DashBoardTiles = ({ selectedJob }) => {
  // Data structure for the dashboard
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardOverviewData, setDashboardOverviewData] = useState([]);
  const [stats, setStats] = useState([]);
  const [jobStats, setJobStats] = useState([]);

  useEffect(() => {
    const fetchStats = async () => {
      const result = await getDashboardOverview();
      setStats(result);
    };

    fetchStats();
  }, []);
  useEffect(() => {
    const fetchJobStats = async () => {
      const result = await getJobOverview(selectedJob);
      setJobStats(result);
    };

    fetchJobStats();
  }, [selectedJob]);

  useEffect(() => {
    setDashboardOverviewData([
      {
        items: [
          {
            icon: Briefcase,
            text: "Total jobs created",
            value: stats.totalJobs,
            color: "text-white-500",
            bgColor: "bg-blue-500",
          },
          {
            icon: Users,
            text: "Total contacts",
            value: stats.totalJobseekers,
            bgColor: "bg-green-500",
          },
        ],
      },
      {
        items: [
          {
            icon: Users,
            text: "Average applicants per job",
            value: stats.averageApplicantPerJob,
            color: "text-white-500",
            bgColor: "bg-green-500",
          },
          {
            icon: ClipboardList,
            text: "Average Questionnaire score",
            value: stats.averageQuestionnaireScore,
            color: "text-white-500",
            bgColor: "bg-purple-500",
          },
        ],
      },
      {
        items: [
          {
            icon: CalendarCheck,
            text: "Average interviews booked",
            value: stats.averageInterviewsBooked,
            color: "text-white-500",
            bgColor: "bg-indigo-500",
          },
          {
            icon: CheckCircle,
            text: "Average time to hire (days)",
            value: stats.averageTimeToHire,
            color: "text-white-500",
            bgColor: "bg-blue-500",
          },
        ],
      },
      {
        items: [
          {
            icon: CheckCircle,
            text: "Average hires per job",
            value: stats.averageHirePerJob,
            color: "text-white-500",
            bgColor: "bg-indigo-500",
          },
          {
            icon: PoundSterling,
            text: "Average cost per hire",
            value: `£${stats.averageCostPerHire}`,
            color: "text-white-500",
            bgColor: "bg-pink-500",
          },
        ],
      },
    ]);
  }, [stats]);

  useEffect(() => {
    setDashboardData([
      {
        items: [
          {
            icon: UserCheck,
            text: "Applications",
            value: jobStats.totalJobseekers || 0,
            color: "text-white-500",
            bgColor: "bg-yellow-400",
          },
          {
            icon: Clock,
            text: "Questionnaire completion rate",
            value: jobStats.questionnaireCompletionRate || 0,
            color: "text-white-500",
            bgColor: "bg-purple-500",
          },
        ],
      },
      {
        items: [
          {
            icon: CheckCircle2,
            text: "Shortlisted",
            value: jobStats.sortlistedJobseekerCount || 0,
            color: "text-white-500",
            bgColor: "bg-blue-500",
          },
          {
            icon: Star,
            text: "Average application score",
            value: jobStats.averageApplicationScore || 0,
            color: "text-white-500",
            bgColor: "bg-green-400",
          },
        ],
      },
      {
        items: [
          {
            icon: CalendarCheck,
            text: "Interviews booked",
            value: jobStats.interviewBookedCount || 0,
            color: "text-white-500",
            bgColor: "bg-green-500",
          },
          {
            icon: CheckCircle,
            text: "Time to hire (days)",
            value: jobStats.timeToHire || 0,
            color: "text-white-500",
            bgColor: "bg-indigo-500",
          },
        ],
      },
      {
        items: [
          {
            icon: CheckCircle,
            text: "Hired",
            value: jobStats.hireJobseekersCount || 0,
            color: "text-white-500",
            bgColor: "bg-indigo-500",
          },
          {
            icon: PoundSterling,
            text: "Cost per hire",
            value: "£" + (jobStats.costPerHire || 0),
            color: "text-white-500",
            bgColor: "bg-pink-500",
          },
        ],
      },
    ]);
  }, [jobStats]);

  // Calculate statistics
  return (
    <div>
      <h2 className="text-3xl font-bold mb-8 text-indigo-800">Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {dashboardOverviewData.map((box, index) => (
          <StatsBox key={index} items={box.items} />
        ))}
      </div>
      <h2 className="text-3xl font-bold mb-8 text-indigo-800">Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {dashboardData.map((box, index) => (
          <StatsBox key={index} items={box.items} />
        ))}
      </div>
    </div>
  );
};

export default DashBoardTiles;
