// Constants
import { api } from "./utils/api";
import { ConditionTypes } from "./constants";

export const AnswerTypes = {
  OPEN_ENDED: "Open-ended",
  YES_NO: "Yes/No",
  MULTIPLE_CHOICE: "Multiple Choice",
  RATING: "Rating",
};

export const DifficultyLevels = {
  EASY: "Easy",
  MEDIUM: "Medium",
  HARD: "Hard",
};

export const ImportanceLevels = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  CRITICAL: "Critical",
};

// Helper functions
export const generateUniqueId = () => Date.now().toString();

export const getDefaultConditionValue = (conditionType) => {
  switch (conditionType) {
    case ConditionTypes.YES_NO:
      return "yes";
    case ConditionTypes.KEYWORD:
      return { matchType: "matched", keywords: "" };
    case ConditionTypes.RATING:
      return { type: "lessThan", value: 5 };
    case ConditionTypes.LENGTH:
      return { type: "lessThan", value: 0 };
    case ConditionTypes.CONFIDENCE:
    case ConditionTypes.COMPLETENESS:
      return 50;
    default:
      return "";
  }
};

export const isValidURL = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const hireJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        applicationStatus: "Hired",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const rejectJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        applicationStatus: "Rejected",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const hideJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        hidden: true,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const unHideJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        hidden: false,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const getNameInitials = (name) => {
  // Trim the name and split it into words
  const words = name.trim().split(/\s+/);

  // If the name is empty, return an empty string
  if (words.length === 0) return "";

  // If it's a single word, return the first two characters (or the first character if it's a single-character word)
  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase();
  }

  // Otherwise, return the first character of the first word and the first character of the last word
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
};

export const loadJobsJobseekers = async (jobId) => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/admin/jobseekers/api/job?jobId=${jobId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const uploadFiles = async (files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
    //console.log("Appending file to FormData:", file.name, file.type, file.size);
  });

  let upload_url = "/api/upload";

  if (files[0] && files[0]["customType"]) {
    const customType = files[0]["customType"];

    if (customType === "avatar") {
      upload_url = "/api/upload/avatar";
    }
  }
  try {
    const response = await api.post(upload_url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.fileUrls;
  } catch (error) {
    console.error(
      "Error uploading files:",
      error.response ? error.response.data : error.message,
    );
    throw error;
  }
};

export const generateQuestionsWithAI = async (job) => {
  const token = localStorage.getItem("token");
  const response = await api.get(
    `/api/jobs/generate/questionnaire?jobId=${job}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const jobQuestionnaire = async (job) => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/api/jobs/questionnaire?jobId=${job}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const chaseJobseekerForQuestionnaire = async (jobseeker, job) => {
  const token = localStorage.getItem("token");
  return api
    .post(
      `/api/jobs/questionnaire/invite`,
      { jobseeker, job },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return false;
    });

  /* return (
    process.env.REACT_APP_SITE_URL +
    `?mode=chat&for=a&j=${jobseekerId}&job=${selectedJob}`
  ); */
};

export const jobseekerPendingDetails = async (jobId, jobseeker) => {
  const response = await api.get(
    `/api/jobseekers/pending/details?job=${jobId}&jobseeker=${jobseeker}`,
  );
  return response.data;
};

export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const calculatePercentile = (data, value) => {
  // Step 1: Sort the data in ascending order
  data.sort((a, b) => a - b);

  // Step 2: Find the rank of the value in the sorted dataset
  let rank = data.findIndex((item) => item === value) + 1; // rank is 1-based

  if (rank === 0) {
    return null; // Value not found in the dataset
  }

  // Step 3: Apply the percentile formula
  let percentile = ((rank - 0.5) / data.length) * 100;

  return percentile;
};

export const getChecklistForJob = async (jobid) => {
  const checklist = await api.get(`/api/jobs/checklist/${jobid}`);
  return checklist.data;
};

export const saveCheckList = async (jobid, jobseekerid, checklist) => {
  const token = localStorage.getItem("token");
  const saved = await api.post(
    `/api/jobseekers/checklist`,
    { jobid, jobseekerid, checklist },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return saved.data;
};

export const saveQuestion = async ({ questions, jobId, deletedQuestions }) => {
  const token = localStorage.getItem("token");
  const saved = await api.post(
    `/api/jobs/save/questionnaire`,
    { questions, jobId, deletedQuestions },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return saved.data;
};

export const calculateAverageTimeToHire = (jobseekers) => {
  // Filter jobseekers who are hired and have both appliedDate and updatedAt
  const hiredJobseekers = jobseekers.filter(
    (jobseeker) =>
      jobseeker.applicationStatus === "Hired" &&
      jobseeker.appliedDate &&
      jobseeker.hiredDate,
  );

  if (hiredJobseekers.length === 0) {
    return 0; // No hires yet
  }

  // Calculate total days for all hired jobseekers
  const totalDays = hiredJobseekers.reduce((total, jobseeker) => {
    const appliedDate = new Date(jobseeker.appliedDate);
    const hiredAt = new Date(jobseeker.hiredDate);
    const timeDifference = (hiredAt - appliedDate) / (1000 * 60 * 60 * 24); // Convert ms to days
    return total + timeDifference;
  }, 0);

  // Return the average time to hire
  return Math.ceil(totalDays / hiredJobseekers.length); // in days
};

export const calculateAverageApplicantScore = (jobseekers) => {
  const scores = [];
  for (let jobseeker of jobseekers) {
    if (jobseeker.score) {
      scores.push(jobseeker.score);
    }
  }

  if (scores.length === 0) {
    return 0;
  }

  const total = scores.reduce((acc, score) => acc + score, 0);
  return (total / scores.length).toFixed(1);
};

export const calculateAvgCostPerHire = async () => {
  const token = localStorage.getItem("token");
  try {
    const avgCost = await api.get(`/api/companies/cost-per-hire`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return avgCost.data ? avgCost.data.average_cost : 0;
  } catch (error) {
    return 0;
  }
};

export const addOrdinalSuffix = (num) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = num % 100; // Get last two digits
  if (value >= 11 && value <= 13) {
    return `${num}${suffixes[0]}`; // "th" for 11, 12, 13
  }
  switch (num % 10) {
    case 1:
      return `${num}${suffixes[1]}`; // "st" for 1
    case 2:
      return `${num}${suffixes[2]}`; // "nd" for 2
    case 3:
      return `${num}${suffixes[3]}`; // "rd" for 3
    default:
      return `${num}${suffixes[0]}`; // "th" for everything else
  }
};

export const questionnaireStatus = async (jobId) => {
  const token = localStorage.getItem("token");
  const saved = await api.get(`/api/jobs/questionnaire/status/${jobId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return saved.data;
};

export const getJobDetails = async (jobId) => {
  try {
    const response = await api.get(`/api/jobs/find/${jobId}`, {});
    return response.data ?? [];
  } catch (error) {
    return 0;
  }
};

export const generateDescription = async (details, wordCount = 120) => {
  try {
    const response = await api.post(`/api/jobs/generate/description`, {
      details: details,
      wordCount,
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getTokenDetail = async (token = false) => {
  if (!token) {
    const searchParams = new URLSearchParams(window.location.search);
    token = searchParams.get("token");
  }
  try {
    const _t = await api.get(`/api/jobseekers/token/${token}`);
    return _t.data ?? false;
  } catch (error) {
    return false;
  }
};

export const saveReference = async (ratings, comment, jobseekerId, request) => {
  try {
    await api.post(`/api/reference`, {
      ratings,
      comment,
      jobseekerId,
      request,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const updateTokenStatus = async (token, isUsed) => {
  try {
    const response = await api.put(`/api/jobseekers/token/${token}`, {
      isUsed,
    });
    return response.data;
  } catch (error) {}
};

export const rejectReference = async (token, request) => {
  try {
    const response = await api.put(`/api/reference/reject`, {
      token,
      request,
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getDashboardOverview = async () => {
  try {
    const response = await api.get(`api/companies/overview`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const sortedJobseekers = (data, by, order) => {
  // If no sorting parameter is provided, use default sorting
  if (!by || by === "") {
    return data.sort((a, b) => {
      // Default sorting logic: completed questionnaires first, then by score
      if (
        a.questionnaireStatus === "completed" &&
        b.questionnaireStatus !== "completed"
      ) {
        return -1;
      }
      if (
        a.questionnaireStatus !== "completed" &&
        b.questionnaireStatus === "completed"
      ) {
        return 1;
      }
      if (
        a.questionnaireStatus === "completed" &&
        b.questionnaireStatus === "completed"
      ) {
        return a.score >= b.score ? -1 : 1;
      }
      return 0;
    });
  }

  // Sort based on the provided parameter
  return data.sort((a, b) => {
    switch (by) {
      case "name":
        return order === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);

      case "appliedBy":
        return order === "asc"
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);

      case "qStatus":
        if (a.questionnaireStatus === b.questionnaireStatus) {
          return 0;
        }
        return order === "asc"
          ? a.questionnaireStatus === "completed"
            ? -1
            : 1
          : a.questionnaireStatus === "completed"
            ? 1
            : -1;

      case "score":
        const scoreA = a.score ?? 0;
        const scoreB = b.score ?? 0;
        return order === "asc" ? scoreA - scoreB : scoreB - scoreA;

      default:
        return 0;
    }
  });
};
export const getJobOverview = async (selectedJob) => {
  try {
    const response = await api.get(`api/jobs/overview/${selectedJob}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const calculateMessageSegments = (message) => {
  // Check if message contains any characters outside GSM-7 alphabet
  const gsm7Regex =
    /^[@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()*+,\-./0-9:;<=>?¡A-ZÄÖÑÜa-zäöñüà\s\n]*$/;
  const isGsm7 = gsm7Regex.test(message);

  // Set character limit based on encoding
  const charsPerSegment = isGsm7 ? 160 : 70;

  // Calculate number of segments
  return Math.ceil(message.length / charsPerSegment);
};
