import { api } from "./api";

export const uploadCompanyLogo = async (file) => {
  const token = localStorage.getItem("token");
  if (!file) {
    alert("Please select a file to upload.");
    return;
  }

  const formData = new FormData();
  formData.append("logo", file);

  try {
    const response = await api.post("/api/upload/logo", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    return;
  }
};

export const uploadMessageAttachments = async (files) => {
  const token = localStorage.getItem("token");
  if (!files) {
    alert("Please select a file to upload.");
    return;
  }

  if (files.length > 3) {
    alert("You can only upload 3 attachments at once");
    return false;
  }

  const formData = new FormData();
  for (const file of files) {
    formData.append("attachments", file);
  }

  try {
    const response = await api.post("/api/upload/attachments", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    return;
  }
};
