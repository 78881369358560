import React, { useState, useEffect, useCallback, useParam } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import QuestionnaireBuilder from "./QuestionnaireBuilder";
import ChatInterface from "./ChatInterface";
import AuthScreen from "./AuthScreen";
import Header from "./Header";
import JobseekerList from "./JobseekerList";
import JobListing from "./JobListing";
import SwimLanes from "./SwimLanes";
import Dashboard from "./Dashboard";
import Billing from "./Billing";
import CompanySettings from "./CompanySettings";
import { NotificationProvider } from "./CustomNotificationSystem";
import ForgotPasswordPage from "./ForgotPassword";
import ResetPasswordPage from "./ResetPassword";
import JobPostForm from "./JobPostForm";
import WorkerManagement from "./WorkerManagement";
import ReferenceForm from "./RefereceInvite";
import ReferenceReject from "./ReferenceReject";
import axios from "axios";
import { API_BASE_URL } from "./utils/api";
import { loadJobsJobseekers } from "./utils";

// Create an axios instance with default headers
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: "application/json",
  },
});

// Protected Route Component
const ProtectedRoute = ({ children, isLoggedIn, isLoading }) => {
  if (isLoading) return <div>Loading...</div>;
  if (!isLoggedIn) return <Navigate to="/login" replace />;
  return children;
};

// Auth Routes Component
const AuthRoutes = ({ onLogin, userToken, pageFor }) => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <AuthScreen
            onLogin={onLogin}
            changeToForgotPassword={() => navigate("/forgot-password")}
          />
        }
      />
      <Route
        path="/forgot-password"
        element={
          <ForgotPasswordPage changeView={(view) => navigate(`/${view}`)} />
        }
      />
      <Route
        path="/reset-password"
        element={<ResetPasswordPage token={userToken} />}
      />
      <Route
        path="/chat"
        element={<ChatInterface selectedJob={null} userType={pageFor} />}
      />
      <Route
        path="/reference/invite"
        element={<ReferenceForm token={userToken} />}
      />
      <Route
        path="/reference/reject"
        element={<ReferenceReject token={userToken} />}
      />

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

// Main App Layout Component
const AppLayout = ({
  user,
  jobs,
  jobseekers,
  handleLogout,
  handleJobChange,
  selectedJob,
  company,
  handleJobCreation,
  handleCompanySave,
  setJobseekers,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Convert currentView path mappings to match route definitions
  const viewToPath = {
    dashboard: "/",
    createJob: "/create-job",
    questionBuilder: "/questionnaire",
    chatInterface: "/chat",
    jobseekerList: "/jobseekers",
    jobListing: "/jobs",
    swimLanes: "/swimlanes",
    workerManagement: "/worker-management",
    companysettings: "/company-settings",
    billing: "/billing",
    editJob: "/job/edit/:jobId",
  };

  const handleMenuItemClick = useCallback(
    (view) => {
      const path = viewToPath[view] || "/";
      navigate(path);
    },
    [navigate],
  );

  const getCurrentView = useCallback(() => {
    const path = location.pathname;
    // Remove leading slash and find the corresponding view
    const currentPath = path.substring(1);
    return currentPath || "dashboard";
  }, [location]);

  // Create a wrapper component to handle the parameters
  const JobEditWrapper = ({ company }) => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    //const initialData = await getJobDetails(jobId);
    return (
      <JobPostForm
        company={company}
        setCurrentView={(view) => navigate(viewToPath[view] || "/")}
        setSelectedJob={selectedJob}
        jobId={jobId}
      />
    );
  };

  return (
    <div className="App bg-gray-100 min-h-screen">
      <Header
        selectedJob={selectedJob}
        onJobChange={handleJobChange}
        jobs={jobs}
        onMenuItemClick={handleMenuItemClick}
        currentView={getCurrentView()}
        onLogout={handleLogout}
        user={user}
        company={company}
      />
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard
                jobs={jobs}
                selectedJob={selectedJob}
                jobseekers={jobseekers}
                company={company}
                onNavigate={(view, jobseekerId) => {
                  const path = viewToPath[view] || "/";
                  navigate(jobseekerId ? `${path}/${jobseekerId}` : path);
                }}
              />
            }
          />

          <Route
            path="/create-job"
            element={
              <JobPostForm
                company={company}
                setCurrentView={(view) => navigate(viewToPath[view] || "/")}
                setSelectedJob={handleJobCreation}
              />
            }
          />

          <Route
            path="/job/edit/:jobId"
            element={<JobEditWrapper company={company} />}
          />

          <Route
            path="/questionnaire/:jobId?"
            element={<QuestionnaireBuilder selectedJob={selectedJob} />}
          />
          <Route
            path="/chat"
            element={
              <ChatInterface
                selectedJob={selectedJob}
                onJobChange={handleJobChange}
                jobs={jobs}
                userType="c"
              />
            }
          />

          <Route
            path="/jobseekers/:jobseekerId?"
            element={
              <JobseekerListWrapper
                selectedJob={selectedJob}
                initialJobseekers={jobseekers}
                jobs={jobs}
                currentUser={user}
                currentCompany={company}
              />
            }
          />

          <Route
            path="/jobs"
            element={
              <JobListing
                onJobSelected={(jobId) => navigate(`/questionnaire/${jobId}`)}
                onViewJobseekers={(jobId) =>
                  navigate(`/jobseekers?job=${jobId}`)
                }
                onViewSwimLanes={(jobId) => navigate(`/swimlanes/${jobId}`)}
                onEditJob={(jobId) => navigate(`/job/edit/${jobId}`)}
                companyJobs={jobs}
                onMenuItemClick={handleMenuItemClick}
              />
            }
          />

          <Route
            path="/swimlanes/:jobId?"
            element={
              <SwimLanes
                selectedJob={selectedJob}
                jobseekers={jobseekers}
                setJobseekers={setJobseekers}
                onNavigate={(view, jobseekerId) => {
                  const path = viewToPath[view] || "/";
                  navigate(jobseekerId ? `${path}/${jobseekerId}` : path);
                }}
                currentUser={user}
              />
            }
          />

          <Route path="/worker-management" element={<WorkerManagement />} />

          <Route
            path="/company-settings"
            element={
              <CompanySettings
                company={company}
                onSave={handleCompanySave}
                currentUser={user}
              />
            }
          />

          <Route path="/billing" element={<Billing />} />

          {/* Catch all route for invalid paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
};

const JobseekerListWrapper = ({
  selectedJob,
  initialJobseekers,
  jobs,
  currentUser,
  currentCompany,
}) => {
  const { jobseekerId } = useParams();

  return (
    <JobseekerList
      selectedJob={selectedJob}
      expandedJobseekerId={jobseekerId}
      initialJobseekers={initialJobseekers}
      jobs={jobs}
      currentUser={currentUser}
      currentCompany={currentCompany}
    />
  );
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentView, setCurrentView] = useState("dashboard");
  const [selectedJob, setSelectedJob] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobseekers, setJobseekers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [company, setCompany] = useState({});
  const [userToken, setUserToken] = useState(null);
  const [pageMode, setPageMode] = useState(null);
  const [pageFor, setPageFor] = useState(null);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUser(null);
    setJobs([]);
    setJobseekers([]);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const mode = searchParams.get("mode");
    const page_for = searchParams.get("for"); // page is for jobseeker or company
    if (token) {
      setUserToken(token);
    }
    if (mode) {
      setPageMode(mode);
    }
    if (page_for) {
      setPageFor(page_for);
    }
  }, []);

  const fetchJobs = useCallback(async (companyId) => {
    try {
      if (!companyId) {
        console.error("Company ID is undefined");
        return [];
      }
      const token = localStorage.getItem("token");
      const response = await api.get(`/admin/jobs/api/company/${companyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching jobs from API:", error);
      throw error;
    }
  }, []);

  const fetchJobseekersForJob = useCallback(async (jobId) => {
    try {
      const data = await loadJobsJobseekers(jobId);
      setJobseekers(data);
    } catch (error) {
      console.error(`Error fetching jobseekers for job ${jobId}:`, error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchInitialData = useCallback(
    async (userData) => {
      setIsLoadingInitialData(true);
      try {
        if (!userData || !userData.company) {
          console.error("No company ID found in user data:", userData);
          return;
        }
        const jobsData = await fetchJobs(userData.company);
        setJobs(jobsData);
        if (jobsData.length > 0) {
          const firstJobId = jobsData[0]._id;
          setSelectedJob(firstJobId);
          await fetchJobseekersForJob(firstJobId);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoadingInitialData(false);
      }
    },
    [fetchJobs, fetchJobseekersForJob],
  );

  const handleLogin = async (email, password) => {
    try {
      const response = await api.post("/api/auth/login", {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token);
      setIsLoggedIn(true);
      setUser(response.data.user);
      await fetchInitialData(response.data.user);
      window.location.href = "."; // reloading page once, so currentview gets set for the dahsboard
    } catch (error) {
      alert(error.response.data.message);
      //toast(error.response.data.message);
    }
  };

  const checkAuthStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await api.get("/api/auth/check", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.isAuthenticated) {
          setIsLoggedIn(true);
          setUser(response.data.user);
          await fetchInitialData(response.data.user);
        } else {
          handleLogout();
        }
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error("Error checking auth status:", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    } finally {
      setIsLoading(false);
    }
  }, [fetchInitialData, handleLogout]);

  const handleCompanySave = async (updatedCompany) => {
    // Handle saving the updated company data
    // This might involve an API call to your backend
    const token = localStorage.getItem("token");
    if (token) {
      const response = await api.put(
        `/api/companies/${company._id}`,
        updatedCompany,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.status === 200) setCompany(updatedCompany);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    if (selectedJob) {
      fetchJobseekersForJob(selectedJob);
    }
  }, [selectedJob, fetchJobseekersForJob]);

  useEffect(() => {
    const loadCompany = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await api.get("/api/companies/current", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          setCompany(response.data);
        }
      }
    };
    if (
      currentView === "companysettings" ||
      currentView === "dashboard" ||
      currentView === "jobseekerList"
    )
      loadCompany();
  }, [currentView]);

  const handleJobChange = useCallback(
    (e) => {
      const newJobId = e.target.value;
      setSelectedJob(newJobId);
      // Ensure jobseekers are fetched for the new job
      fetchJobseekersForJob(newJobId);
    },
    [fetchJobseekersForJob],
  );

  // Add a new function to handle job creation
  const handleJobCreation = useCallback(
    async (jobId) => {
      try {
        // Refresh the jobs list
        const updatedJobs = await fetchJobs(user?.company);
        setJobs(updatedJobs);
        // Set the new job as selected
        setSelectedJob(jobId);
        // Fetch jobseekers for the new job
        await fetchJobseekersForJob(jobId);
      } catch (error) {
        console.error("Error handling new job creation:", error);
      }
    },
    [fetchJobs, fetchJobseekersForJob, user?.company],
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isLoadingInitialData) {
    return <div>Loading dashboard data...</div>;
  }

  if (!isLoggedIn) {
    return (
      <BrowserRouter>
        <NotificationProvider>
          <AuthRoutes
            onLogin={handleLogin}
            userToken={userToken}
            pageFor={pageFor}
          />
        </NotificationProvider>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <NotificationProvider>
        <ProtectedRoute
          isLoggedIn={isLoggedIn}
          isLoading={isLoading || isLoadingInitialData}
        >
          <AppLayout
            user={user}
            jobs={jobs}
            jobseekers={jobseekers}
            handleLogout={handleLogout}
            handleJobChange={handleJobChange}
            selectedJob={selectedJob}
            company={company}
            handleJobCreation={handleJobCreation}
            handleCompanySave={handleCompanySave}
            setJobseekers={setJobseekers}
          />
        </ProtectedRoute>
      </NotificationProvider>
    </BrowserRouter>
  );
}

export default App;
