import React, { useEffect, useState } from "react";
import CustomCreatableSelect from "./CustomCreatableSelect";
import Select from "react-select";
import { useTag } from "../services/Tags";
import { useNotification } from "../CustomNotificationSystem";

const TagInterface = ({
  jobseekers,
  jobseekerList,
  companyUser,
  currentCompany,
  handleAddTags,
}) => {
  const { loading, error, getTagsByCompany, createTag, createJobseekerTag } =
    useTag();
  const [tags, setTags] = useState([]);
  const [tagJobseekers, setTagJobseekers] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const notify = useNotification();

  useEffect(() => {
    const loadCompanyTags = async () => {
      const t = await getTagsByCompany(currentCompany._id);
      const tagsoptions = t.map((tag) => ({
        id: tag._id,
        name: tag.name,
      }));
      setTags(tagsoptions);
    };
    loadCompanyTags();
  }, [currentCompany]);

  const handleCreateTag = async (tag) => {
    try {
      const newTag = await createTag({
        tagJobseekers,
        name: tag,
        user: companyUser.id,
        company: currentCompany._id,
      });
      setTags((prev) => [...prev, { id: newTag._id, name: newTag.name }]);
      setSelectedTags((prev) => [...prev, newTag._id]);
    } catch (error) {
      notify("Tag can not be created!", "error");
    }
  };
  const customSelectedJobseeker = jobseekers.map((j) => ({
    value: j.id,
    label: j.name,
  }));
  const customSelectOptions = jobseekerList.map((jobseeker) => ({
    value: jobseeker._id,
    label: jobseeker.name,
  }));
  const handleSelectedJobseekers = (value) => {
    const jobseekerIds = value.map((j) => j.value);
    setTagJobseekers(jobseekerIds);
  };
  const handleTagChange = (value) => {
    const tids = value.map((tagId) => tagId);
    setSelectedTags(tids);
  };
  const saveJobseekerTags = async () => {
    try {
      const jobseeker = customSelectedJobseeker.map((j) => j.value);
      const finalJobseekers = new Set([...jobseeker, ...tagJobseekers]);
      const t = await createJobseekerTag({
        tags: selectedTags,
        jobseekers: Array.from(finalJobseekers),
      });
      handleAddTags(t.assignedTags ?? []);
      notify("Tag assigned to jobseekers");
    } catch (error) {
      notify(error.message);
    }
  };
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      "&:hover": {
        borderColor: "#9ca3af",
      },
      boxShadow: state.isFocused ? "0 0 0 1px #6366f1" : "none",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#6366f1" // indigo-500 for selected
        : isFocused
          ? "#e0e7ff" // indigo-100 for hover
          : null,
      color: isSelected ? "white" : "#374151",
      ":active": {
        backgroundColor: "#6366f1", // indigo-500
      },
      ":hover": {
        backgroundColor: "#6366f1", // indigo-500
        color: "white",
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#e0e7ff", // indigo-100
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#201e61", // indigo-600
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ":hover": {
        backgroundColor: "#ef4444",
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
  };
  return (
    <div>
      <div className="p-2">
        <label className="text-sm">Jobseekers</label>
        <Select
          defaultValue={customSelectedJobseeker}
          isMulti={true}
          name="colors"
          options={customSelectOptions}
          className="w-full basic-multi-select"
          classNamePrefix="select"
          styles={customStyles}
          onChange={handleSelectedJobseekers}
        />
      </div>
      <div className="p-2">
        <label className="text-sm">Tags</label>
        <CustomCreatableSelect
          options={tags}
          value={selectedTags}
          onChange={handleTagChange}
          onCreateOption={handleCreateTag}
          height="1rem"
          className="w-60"
        />
      </div>
      <button
        onClick={saveJobseekerTags}
        className="hidden md:flex items-center gap-2 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors sm:text-sm mt-4"
      >
        Save
      </button>
    </div>
  );
};

export default TagInterface;
